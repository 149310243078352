import React from "react";
// import dotenv from "dotenv";
import axios from "axios";
// import readline from 'readline';
import { useState } from "react";
import Loader from "./Loader";

const Home = () => {
  const [productName, setProductName] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  async function classifyProduct() {
    setResult("");
    setLoading(true);
    const prompt = `
        Using the following product categorization template:
    
        Product Name: MH NDRV-DN12-G1/2" 1A Flow Control Valve
        Industry: Industrial Equipment and Supplies
        Sub-Industry: Fluid Control Systems
        Sub to Sub-Industry: Valves and Fittings
        Product Type: Flow Control Valve
        Product Sub-Type: Piping System Valve
        Product Sub to Sub-Type: DN12 Flow Control Valve
        Product Stage: Finished Product
        Applications: Piping Systems, Fluid Flow Regulation, Industrial and Commercial Plumbing
        Consumer Type: Industrial Facilities, Construction Companies, Plumbing Contractors
    
        Based on this template and the provided data, categorize the following product:
        
        Product Name: ${productName}
    
        Use the same format as the template and replace each category with the relevant information. If some categories don't fit or data is missing, make an educated guess based on the product's name and type.
        `;

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4",
          messages: [
            {
              role: "system",
              content:
                "You are a helpful assistant that categorizes products based on provided classifications.",
            },
            { role: "user", content: prompt },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${"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"}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Extracting and displaying the response content
      const result = response.data.choices[0].message.content.trim();
      setResult(result);
      console.log(result);
    } catch (error) {
      console.error("Error fetching response from OpenAI API:", error);
    } finally {
      setLoading(false); // Set loading to false once API call is complete
    }
  }
  return (
    <div className="m-8">
      <div className="border border-gray-800 shadow-lg rounded-lg p-6 w-full max-w-4xl bg-white ">
        <div className="flex flex-col md:flex-row items-start md:items-center gap-4 mb-6">
          <div className="bg-blue-700 w-fit p-2 text-white font-bold rounded-lg">
            Question
          </div>
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Add Question"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="border border-gray-400 p-3 pr-12 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
            <button
              onClick={classifyProduct}
              className="absolute right-2 top-1/2 transform -translate-y-1/2  text-white w-10 h-10 flex items-center justify-center rounded-full focus:outline-none text-xl font-bold "
            >
              <img src="./send.png " className="rounded-full h-8 w-7" />
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start gap-4">
          <div className="bg-yellow-500 text-black h-fit w-fit p-2 font-bold rounded-lg mt-1">
            Answers
          </div>
          <div className="relative flex-1 w-full h-96 flex items-center justify-center">
            {loading && (
              <div className="absolute flex items-center justify-center w-full h-full bg-white bg-opacity-80">
                <Loader /> 
              </div>
            )}
            <textarea
              className="border border-gray-400 p-3 rounded-lg w-full h-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
              value={result}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
